<template>
    <div class="ImageDownloadPage">
        <GradientBackLayout :title="$t('download_page_header')" :shadowText="$t('download_page_header')">
            <BoxShadow class="ImageDownloadPage--Box">
                <p>{{ $t('download_page_info') }}</p>
                <div class="ImageDownloadPage--Form">
                    <SuiteInput class="Floating" type="text" v-model="imageUrl" :label="$t('download_page_url')" />
                    <CommunityButton v-on:click="downloadImage()">{{$t('action_download')}}</CommunityButton>
                </div>
            </BoxShadow>
        </GradientBackLayout>
    </div>
  </template>
  
  <script>
  //import { externalUrlToBase64 } from '~/utils/watMonitor'
  import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
  import GradientBackLayout from '@/layouts/GradientBackLayout'
  import BoxShadow from '@/components/BoxShadow.vue'
  import CommunityButton from '@/components/CommunityButton.vue'
  export default {
    name: 'ImageDownloadPage',
    components:{
        GradientBackLayout,
        BoxShadow,
        SuiteInput,
        CommunityButton
    },
    data(){
        return{
            imageUrl: ''
        }
    },
    methods:{
        async downloadImage(){
            var downloadLink = document.createElement('a');
            downloadLink.href = this.imageUrl;
            downloadLink.target = "_blank"
            downloadLink.download = 'image.jpg';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            this.imageUrl = ''
        }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .ImageDownloadPage{
    &--Form{
        display: flex;
        gap: 30px;
        align-items: center;
    }
    p{
        opacity: 0.5;
        margin-bottom: 10px;
    }
    &--Box{
        display: flex;
        flex-direction: column;
    }
    .Floating{
        margin: 10px 0;
    }
  }
  </style>
  